import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Intro from '../components/Intro';
import { P } from '../components/Typography';
import Container from '../components/Container';
import Button from '../components/Button';
import List from '../components/List';
import ContactTeaser from '../components/ContactTeaser';

import { getImageFromNode } from '../helpers/utils';

/** Bild im Rechts-Links-Wechsel */
const Img = styled(GatsbyImage)`
    width: 100%;
`;

const ServicesPage = ({ data }) => {
    const { stageImage, contentImages } = data;
    return (
        <Layout title="Leistungen">
            <Stage image={stageImage.childImageSharp} title="Leistungen" />
            <Section>
                <Container size="l" grid cols="2" alignItems="center" reverseOnMobile>
                    <Intro headline="Von A wie Akustik bis Z wie Zusatzbeleuchtung">
                        <P>
                            Elektrotechnik aller Art – bei Modernisierung, Altbausanierung, Neubau
                            von Ein- und Mehrfamilienhäusern, bei privaten oder gewerblichen
                            Auftraggebern – stellt uns heute in ihrer Vielseitigkeit vor höheren
                            Anforderungen als vor einigen Jahren. Mit unserem individuellem Know-how
                            und breitgefächerten Kompetenzen finden wir für alle Situationen die
                            beste Lösung.
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'services/contentimage-intro.jpg').node
                                .childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section bg="gray" id="elektroinstallationen">
                <Container size="l" grid cols="2" alignItems="center">
                    <Img
                        fluid={
                            getImageFromNode(
                                contentImages,
                                'services/contentimage-elektroinstallationen.jpg'
                            ).node.childImageSharp.fluid
                        }
                    />
                    <Intro headline="Elektroinstallationen">
                        <P>
                            Unser qualifiziertes Fachpersonal zeigt unseren Kunden die
                            faszinierenden Möglichkeiten einer modernen Elektroinstallation mit
                            zukunftsweisenden und begeisternden Lösungen und ist gerne für Sie im
                            Einsatz.
                        </P>
                        <List
                            title="Klassische Elektroinstallationen:"
                            items={[
                                'Klassische Elektroinstallationen',
                                'Haus- und Gebäudetechnik',
                                'Beleuchtungstechnik',
                                'Daten- und Sicherheitstechnik',
                            ]}
                            showBullets
                        />
                    </Intro>
                </Container>
            </Section>
            <Section id="smarthome">
                <Container size="l" grid cols="2" alignItems="center" reverseOnMobile>
                    {/* eslint-disable react/jsx-wrap-multilines */}
                    <Intro
                        headline={`Gebäude Automation <br> (Smart Home)`}
                        cta={<Button to="/loxone">Mehr über Loxone erfahren</Button>}
                    >
                        {/* eslint-enable react/jsx-wrap-multilines */}
                        <P>
                            Ein Loxone Smart Home ist ein intelligentes Haus, das die meisten
                            Aufgaben rund um Sicherheit, Komfort und Energie-Effizienz von selbst
                            erledigt. Gerne kümmern wir uns auch um ihr Projekt. Aktuell sind wir
                            als Silber Partner bei der Firma Loxone gelistet.
                        </P>
                        <List
                            title="Möglichkeiten der Gebäude Automation:"
                            items={[
                                'Beleuchtung',
                                'Beschattung',
                                'Steuerung der Heizung und Lüftung',
                                'Alarmanlage und Sicherheit',
                                'Laden von Elektrofahrzeugen',
                                'uvm.',
                            ]}
                            showBullets
                        />
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'services/contentimage-smart-home.jpg')
                                .node.childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section id="photovoltaik" bg="gray">
                <Container size="l" grid cols="2" alignItems="center">
                    <Img
                        fluid={
                            getImageFromNode(
                                contentImages,
                                'services/contentimage-photovoltaik.jpg'
                            ).node.childImageSharp.fluid
                        }
                    />
                    <Intro headline="Photovoltaik">
                        <P>
                            Alles aus einer Hand: Wir bieten einen komplett Service von der Planung
                            bis hin zur Montage und Inbetriebnahme. Mit unserer mehr als 15 Jährigen
                            Erfahrung im Bereich der Photovoltaik können wir auf ein breites
                            Fachwissen im Gebiet der Regenerativen Energien zurück greifen.
                        </P>
                        <List
                            title="Rundum-Service"
                            items={[
                                'Planung, Installation und Reparatur von Solarmodulen / Anlagen',
                                'Reinigung und Instandsetzung',
                                'Langjährige Erfahrung',
                                'Kompetente Beratung',
                            ]}
                            showBullets
                        />
                    </Intro>
                </Container>
            </Section>
            <ContactTeaser />
            {/* <Section>
                <Headline level="h2" textAlign="center">
                    Eine Auswahl unserer letzten Projekte
                </Headline>
            </Section> */}
        </Layout>
    );
};

ServicesPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "services/stage-image.jpg" }) {
            ...StageFluid
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "services/contentimage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 585, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default ServicesPage;
